@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.styled-text {
  opacity: 0.5;
}
.text-isometric {
  font-weight: 900;
  font-size: 30vh;
  text-shadow: -1px -1px 0px $primary, -2px -2px 0px $primary,
    -3px -3px 0px $primary, -4px -4px 0px $primary, -5px -5px 0px $primary,
    -6px -6px 0px $primary, -7px -7px 0px $primary, -8px -8px 0px $primary,
    -9px -9px 0px $primary, -10px -10px 10px $primary;
}
.text-isometric-transform {
  transform: skewY(355deg);
  -webkit-transform: skewY(355deg);
  -moz-transform: skewY(355deg);
  -o-transform: skewY(355deg);
  -ms-transform: skewY(355deg);
}

@media screen and (max-width: 600px) {
  .text-isometric {
    font-size: 20vh;
  }
}
